import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text } from 'components/AppStyle/Text';
import { Input } from 'components/AppStyle/Input';
import { CTA } from 'components/AppStyle/Button';

import { sendMessage } from 'services';

const FormStyle = styled.form`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: .5rem;
  text-align: center;
  background-color: ${props => props.theme.color.backgroundWhite};
`;

const FormTitle = styled(Text)`
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
`;

const FormSubtitle = styled(Text)`
  font-size: 1.6rem;
  line-height: 1.2;
`;

const FormInput = styled(Input)`
  font-size: 2rem;
  background-color: transparent;
  border-bottom: 1px solid ${props => props.theme.color.primaryColor};
`;

const FormTextarea = styled(FormInput)`
  min-height: 100px;
  max-height: 200px;
  resize: vertical;
`;

const Form = () => {
  const [client, setClient] = useState({});
  const [pending, setPending] = useState(false);
  const { t } = useTranslation();
  const handleSubmit = event => {
    event.preventDefault();
    setPending(true)
    sendMessage(client)
      .then(() => {
        setClient({});
        setPending(false);
      });
  }
  return (
    <FormStyle onSubmit={handleSubmit}>
      <FormTitle>
        {t('contact-form.title')}
      </FormTitle>
      <FormSubtitle>
        {t('contact-form.subtitle')}
      </FormSubtitle>
      <FormInput
        type="text"
        value={client.name || ''}
        onChange={e => setClient({ ...client, name: e.target.value })}
        placeholder={t('placeholder-name')}
      />
      <FormInput
        type="text"
        value={client.email || ''}
        onChange={e => setClient({ ...client, email: e.target.value })}
        placeholder={t('placeholder-email')}
      />
      <FormTextarea
        as="textarea"
        value={client.messages || ''}
        onChange={e => setClient({ ...client, messages: e.target.value })}
        placeholder={t('placeholder-message')}
      />
      <CTA type="submit" disabled={pending}>
        <span>{t('button.contact-us')}</span>
      </CTA>
    </FormStyle>
  );
};

export default Form;
