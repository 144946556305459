import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Column } from 'components/AppStyle/Flexbox';
import { Text } from 'components/AppStyle/Text';
import { CTA } from 'components/AppStyle/Button';

const MainText = styled(Text)`
  font-family: ${props => props.theme.subFamily};
  margin: 0;
  line-height: 1.2;
  font-size: 2rem;
  font-weight: 600;
`;

const MainDescription = styled(MainText)`
  font-size: 3rem;
  line-height: 1.5;
`;

const MainSection = styled(Column)`
  min-height: 100vh;
`;


const MainContainer = () => {
  const { t } = useTranslation();
  const handleClick = e => {
    e.preventDefault();
    const { offsetTop = 0 } = document.querySelector('.contact-section');
    window.scroll({
      top: offsetTop,
      behavior: 'smooth'
    });
  }
  return (
    <MainSection>
      {/* <MainText>We transform your ideas</MainText>
      <MainText>into ready solutions</MainText> */}
      <MainText>{t('main-title')}</MainText>
      <MainDescription>{t('main-description')}</MainDescription>
      <CTA onClick={handleClick}>
        <span>
          {t('cta')}
        </span>
      </CTA>
    </MainSection>
  );
}

export default MainContainer;
