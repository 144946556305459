import axios from 'axios';

export const sendMessage = (data = {}) => {
  const { REACT_APP_SHEET_API_ID } = process.env;
  const {
    name = '--',
    email = '--',
    messages = '--'
  } = data;
  const timestamp = new Date().toISOString()
  return axios({
    method: 'post',
    url: `https://script.google.com/macros/s/${REACT_APP_SHEET_API_ID}/exec`,
    data: { name, email, messages, timestamp },
    headers: {
      'Content-Type': 'text/plain;charset=utf-8',
    },
  })
};
