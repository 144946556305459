import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { H2 } from 'components/AppStyle/Text';
import { Column } from 'components/AppStyle/Flexbox';
import Location from 'components/Contact/Location';
import Form from 'components/Contact/Form';
import Separate from 'components/Separate';
import { mediaQueries } from 'helper/mediaQueries';

const ContactTitle = styled(H2)`
  font-size: 2.5rem;
`;

const ContactSection = styled(Column)`
  min-height: 100vh;
  .separate {
    margin: .5rem;
    ${mediaQueries('md')('flex-direction: column;')}
  }
`;

const ContactRow = styled(Column)`
  padding: 1.5rem 0;
  ${mediaQueries('md')('flex-direction: row;')}
`;

const ContactSide = styled(Column)`
  width: 320px;
`;

const ContactContainer = () => {
  const { t } = useTranslation();
  return (
    <ContactSection className="contact-section">
      <ContactTitle>{t('contact-title')}</ContactTitle>
      <ContactRow>
        <ContactSide>
          <Location
            locality="台中, 台灣"
          />
          <Location
            locality="台南, 台灣"
            tel="+ 886 937398407"
            email="info@vortrupp.com"
          />
        </ContactSide>
        <Separate text="or" />
        <ContactSide>
          <Form />
        </ContactSide>
      </ContactRow>
    </ContactSection>
  );
}

export default ContactContainer;
