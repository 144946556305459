import MainContainer from 'components/PageHome/MainContainer';
import ServicesContainer from 'components/PageHome/ServicesContainer';
import ContactContainer from 'components/PageHome/ContactContainer';

const Home = () => {
  return (
    <div className="page-home">
      <MainContainer />
      <ServicesContainer />
      <ContactContainer />
    </div>
  );
}

export default Home;
