import styled from 'styled-components';
import { Text } from 'components/AppStyle/Text';
import { Column } from 'components/AppStyle/Flexbox';

const Locality = styled.div`
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1.5rem;
`;

const Desc = styled(Text)`
  display: block;
  text-decoration: none;
  line-height: 1.4;
  font-size: 2rem;
  color: ${props => props.theme.color.primaryColor};
  &:hover {
    text-decoration: underline;
  }
`;

const Location = props => {
  const { locality, tel, address, email } = props
  return (
    <Column>
      <Locality>{locality}</Locality>
      {
        address && (
          <Text>
            {address}
          </Text>
        )
      }
      { tel && (
          <Desc as="a" href={`tel: ${tel}`}>
            {tel}
          </Desc>
        )
      }
      {
        email && (
          <Desc as="a" href={`mailto: ${email}`}>
            {email}
          </Desc>
        )
      }
    </Column>
  );
}

export default Location;
