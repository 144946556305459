import styled from 'styled-components';
import { Text } from 'components/AppStyle/Text';

const SeparateStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: normal;
`;

const SeparateText = styled(Text)`
  padding: 1rem;
`;

const SeparateHr = styled.hr`
  flex: 1;
  margin: 0;
  border: 1px solid ${props => props.theme.color.primaryColor};
`;

const Separate = props => {
  const { text } = props;
  return (
    <SeparateStyle className="separate">
      <SeparateHr />
      { text && <SeparateText>{text}</SeparateText> }
      <SeparateHr />
    </SeparateStyle>
  );
}
export default Separate
