import styled from 'styled-components';

const Input = styled.input`
  margin-top: .5rem;
  padding: 1rem 1rem .5rem 0rem;
  border: none;
  border-radius: 0;
  outline: none;
`;

export {
  Input
};
