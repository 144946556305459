import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import styled from 'styled-components';
import { Column, Row } from 'components/AppStyle/Flexbox';
import { H2 } from 'components/AppStyle/Text';

const ServicesSection = styled(Column)`
  height: 50vh;
  padding: 0 2rem;
  color: #ffffff;
  background-color: ${props => props.theme.color.primaryColor};
  .services__row,
  .services__column {
    padding: 2rem;
  }
`;

const ServicesTitle = styled(H2)`
  font-size: 3rem;
  text-align: center;
  color: ${props => props.theme.color.secondaryColor};
`;

const ServicesItem = styled(H2)`
  text-align: center;
`;


const ServicesContainer = () => {
  const { t } = useTranslation();
  return (
    <ServicesSection>
      <ServicesTitle>
        {t('services-title')}
      </ServicesTitle>
      <Row className="services__row">
        <Column className="services__column">
          <ServicesItem>
            {t('services-brand')}
          </ServicesItem>
          <ServicesItem>
            {t('services-ecommerce')}
          </ServicesItem>
        </Column>
        <Column className="services__column">
          <ServicesItem>
            {t('services-mobile')}
          </ServicesItem>
          <ServicesItem>
            {t('services-customize')}
          </ServicesItem>
        </Column>
      </Row>
    </ServicesSection>
  );
}

export default ServicesContainer;
