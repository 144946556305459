import styled from 'styled-components';

const Flex = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent || 'center'};
  align-items: ${props => props.alignItems || 'center'};
`;

const Row = styled(Flex)`
  flex-direction: row;
`;

const Column = styled(Flex)`
  flex-direction: column;
`;

export {
  Row,
  Column
};
