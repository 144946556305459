import styled from 'styled-components';
import { mediaQueries } from 'helper/mediaQueries';

const Button = styled.button`
  border: none;
  font-size: 2rem;
  padding: .5rem 2rem;
  border-radius: 2rem;
  margin: 1rem;
  outline: none;
  cursor: pointer;
`;


const CTA = styled(Button)`
  color: #ffffff;
  border: 4px solid ${({ theme }) => theme.color.primaryColor};
  box-shadow:0px 0px 0px 1px ${({ theme }) => theme.color.primaryColor} inset;
  background-color: ${({ theme }) => theme.color.primaryColor};
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
  :disabled {
    opacity: .3;
    pointer-events: none;
  }
  ${mediaQueries('md')(`
    :hover {
      border: 4px solid ${({ theme }) => theme.color.borderColor};
      background-color: #ffffff;
      box-shadow: none;
      span {
        letter-spacing: 1px;
        color: #333333;
      }
    }
    :after {
      background: #ffffff;
      border: 0px solid ${({ theme }) => theme.color.primaryColor};
      content: "";
      height: 155px;
      left: -75px;
      opacity: .8;
      position: absolute;
      top: -50px;
      -webkit-transform: rotate(35deg);
              transform: rotate(35deg);
      width: 50px;
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      z-index: 1;
    }
    :hover:after {
      background: #ffffff;
      border: 20px solid ${({ theme }) => theme.color.primaryColor};
      opacity: 0;
      left: 120%;
      -webkit-transform: rotate(40deg);
              transform: rotate(40deg);
    }
  `)}
  span {
    transition: all 0.2s ease-out;
    z-index: 2;
  }
`;

export {
  Button,
  CTA
}
